import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import PostHeader from "../../components/PostHeader/PostHeader";
import Layout from "../Layout/Layout";
import "./Article.css";

export const Article = ({ data: { markdownRemark } }) => {
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <Helmet
        title={frontmatter.title}
        meta={[{ name: "keywords", content: frontmatter.tags.join(",") }]}
      />
      <PostHeader date={frontmatter.date} title={frontmatter.title} />
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "D MMMM YYYY")
        tags
        title
      }
    }
  }
`;

Article.propTypes = {
  data: PropTypes.object,
};

export default Article;
