import PropTypes from "prop-types";
import React from "react";

import "./PostHeader.css";

const PostHeader = ({ title, date }) => (
  <div className="post-header">
    <h1 className="post-header__title">{title}</h1>
    <div className="post-header__date">{date}</div>
  </div>
);

PostHeader.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
};

export default PostHeader;
